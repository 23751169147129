import React from "react";
import "./SummerCamp.scss";
import Logo from "../../assets/images/logo.svg";
import SummerCampQR from "../../assets/images/summerCampQR.png";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/Button";

const SummerCamp = () => {
  const navigate = useNavigate();

  return (
    <div className="summerCamp">
      <div className="container">
        <div className="summerCamp_logo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-arrow-left"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#000000"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            onClick={() => navigate("/")}
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M5 12l14 0" />
            <path d="M5 12l6 6" />
            <path d="M5 12l6 -6" />
          </svg>
          <img onClick={() => navigate("/")} src={Logo} alt="creator-zone" />
        </div>

        <div className="summerCamp_heading">
          <h3>Creator Zone Summer of STEAM 2025</h3>
        </div>

        <div className="summerCamp_topText">
          {/* <p>K-8th Grade Summer Camps</p> */}
          <p>
            We’re thrilled to announce the release of Creator Zone’s 2025 Summer
            of STEAM Camps, where art and technology converge to provide a
            dynamic, well-rounded experience for K-8th grade students.
          </p>
          <p>
            Each camp is thoughtfully designed around engaging STEAM themes,
            blending creativity, innovation, and hands-on learning to inspire
            young minds. Every week features a balance of guided, theme-led
            projects and child-led exploration, encouraging campers to turn
            their ideas into reality.
          </p>
          <p>
            Whether your child is exploring new interests or building on
            existing passions, our camps foster skill-building, curiosity, and
            self-expression. From woodworking and costume design to robotics and
            animation, each session offers opportunities to delve into a diverse
            range of exciting activities. With 8 weeks of flexible summer
            programming, families can choose to enroll in individual weeks or
            multiple sessions for a deeper, more immersive experience. We highly
            recommend pairing a Kids Makerspace Camp with themed camps to expand
            knowledge, enhance hands-on practice, and make the most of our
            advanced makerspace equipment.{" "}
          </p>
          <p>
            At Creator Zone, we’re dedicated to nurturing creativity and
            providing a memorable summer experience that sparks joy, growth, and
            endless possibilities.
          </p>
        </div>
        <div className="summerCamp_paras">
          <div>
            <h4>Week 1 (June 23-27): Kids Makerspace Camp (1):</h4>
            <p>
              Dive into the exciting world of makerspace tools with
              instructor-led guidance on 3D printing, laser cutting, sewing,
              T-shirt printing, and mug heat pressing. Kids will also enjoy
              summer-inspired activities, including engaging art and science
              experiments, slime-making, outdoor painting, fort building, and
              bubble play. A great camp to attend with the other themes to
              master foundational skills on makerspace equipment.{" "}
            </p>
            <p>
              Reserve your place for{" "}
              <a href="https://app.ticketjump.com/public/creator-zone/event/ce065c80-7677-42fe-af16-4a5b12cb6f61/booking">
                Kids Makerspace Camp (1) here
              </a>{" "}
            </p>
          </div>

          <div>
            <h4>Week 2 (June 30 - July 4): Arts & Science:</h4>
            <p>
              Explore the relationship between science and art to produce
              creative fusions! Campers will have the opportunity to experiment
              with the science behind popular activities including tie dye,
              paper making & marbling, DIY sidewalk chalk, and exploring the
              captivating world of luminescence with glow-in-the-dark projects.
              Enroll your mad scientists today!
            </p>
            <p>
              Reserve your place for{" "}
              <a href="https://app.ticketjump.com/public/creator-zone/event/ca9897e7-5256-4b0f-971b-b833a27921b1/booking">
                Arts & Science here
              </a>{" "}
            </p>
          </div>

          <div>
            <h4>Week 3 (July 7-11) Craft Garden:</h4>
            <p>
              Let’s explore the natural world around us! Campers will have the
              chance to get their hands dirty dreaming up their own fairy
              garden, building bird houses, wind spinners, bubble wands, and
              enjoying the process of mosaic art.{" "}
            </p>
            <p>
              Reserve your place for{" "}
              <a href="https://app.ticketjump.com/public/creator-zone/event/45b8e91b-75c3-4ea2-8d89-f4f5a2e42dfd/booking">
                {" "}
                Craft Garden here
              </a>{" "}
            </p>
          </div>

          <div>
            <h4>Week 4 (July 14-18): Wearable Arts Camp</h4>
            <p>
              Calling all cosplayers, fashionistas and aspiring theatre kids.
              Explore the art of textiles and costume creation! Campers will
              design and bring their ideas and characters to life, learning
              sewing techniques and gaining an introduction to fabric
              manipulation while crafting imaginative costumes. Continue to week
              2 of costume design if camper wishes to have more time on their
              projects!{" "}
            </p>
            <p>
              Reserve your place for{" "}
              <a href="https://app.ticketjump.com/public/creator-zone/event/3e5978a1-7e1c-40e0-b33e-c38d252c0346/booking">
                Wearable Arts Camp here
              </a>{" "}
            </p>
          </div>

          {/* <div>
            <h4>Week 5 (July 21-25): Costume Design Camp (2)</h4>
            <p>
              Welcoming new and returning costume design campers! Calling all
              cosplayers, fashionistas and theatre students. Explore the art of
              textiles and costume creation! Campers will design and bring their
              ideas and characters to life, learning sewing techniques and
              gaining an introduction to fabric manipulation while crafting
              imaginative costumes.{" "}
            </p>
            <p>
              Reserve your place for{" "}
              <a href="https://app.ticketjump.com/public/creator-zone/event/45a10faf-ca97-4d75-945d-c8081c880360/booking">
                Costume Design Camp (2) here
              </a>{" "}
            </p>
          </div> */}

          <div>
            <h4>
              Week 6 (July 28 - August 1): Animation & Game Art Design Camp
            </h4>
            <p>
              Discover the world of digital creativity with video game and
              animation design! Highlights include the Airport Minecraft
              Challenge, hands-on practice in 2D and 3D animation, and creating
              original digital projects including game art posters.
            </p>
            <p>
              Reserve your place for{" "}
              <a href="https://app.ticketjump.com/public/creator-zone/event/ecf8d0fc-a7f9-4227-9872-9422109b2c48/booking">
                Animation & Game Art Design Camp here
              </a>{" "}
            </p>
          </div>

          <div>
            <h4>Week 7 (August 4-8): Early Introduction to Robotics Camp</h4>
            <p>
              Race, soar, explore! Campers will explore daily exercises that
              teach early concepts of robotics and electronics. They’ll then
              have the chance to put their new learnings to the test by creating
              something that moves—assembling beginner-friendly machines, and
              building STEM kits! With mentors nearby, campers have the
              opportunity to try Laser cutting, 3D printers and crafts available
              at Creator Zone to construct a new invention!
            </p>
            <p>
              Reserve your place for{" "}
              <a href="https://app.ticketjump.com/public/creator-zone/event/8411d92a-8490-4c00-829f-1e8bbfe13048/booking">
                Early Introduction to Robotics Camp here
              </a>{" "}
            </p>
          </div>

          <div>
            <h4>
              Week 8 (August 11-15): Lemonade Stand Young Entrepreneur Camp:
            </h4>
            <p>
              Get ready for hands-on creativity and an entrepreneurial
              adventure! At our Lemonade Stand Business Camp, kids will dive
              into the exciting world of business-building as they learn to
              create, design, and run their very own small enterprise. From
              woodworking and T-shirt printing to laser-cut sign making and
              marketing, this camp is brimming with engaging activities and
              essential life skills that will inspire your camper to shine. The
              experience culminates in a Grand Lemonade Sale at the end of the
              week, where campers proudly showcase their hard work to the
              community! A great camp to include with other themes for campers
              to sell and showcase their summer creations.
            </p>
            <p>
              Reserve your place for{" "}
              <a href="https://app.ticketjump.com/public/creator-zone/event/0b600310-1854-4f91-b7fe-98f6dda089e7/booking">
                Lemonade Stand Young Entrepreneur Camp here
              </a>{" "}
            </p>
          </div>

          <div>
            <h4>Week 9 (Aug 18-22): Kids Makerspace Camp (2): </h4>
            <p>
              Dive into the exciting world of makerspace tools with
              instructor-led guidance on 3D printing, laser cutting, sewing,
              T-shirt printing, and mug heat pressing. Kids will also enjoy
              summer-inspired activities, including engaging art and science
              experiments, slime-making, outdoor painting, fort building, and
              bubble play. Great for previous campers to attend to finish any
              projects they need some more time & guidance with!
            </p>
            <p>
              Reserve your place for{" "}
              <a href="https://app.ticketjump.com/public/creator-zone/event/03a02ac8-868a-4430-957f-b3d082bfa206/booking">
                Kids Makerspace Camp (2) here
              </a>{" "}
            </p>
          </div>

          <div>
            <h3>
              {/* <svg
                height="28"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                fill="#78bee1"
              >
                <path d="M272 384c9.6-31.9 29.5-59.1 49.2-86.2c0 0 0 0 0 0c5.2-7.1 10.4-14.2 15.4-21.4c19.8-28.5 31.4-63 31.4-100.3C368 78.8 289.2 0 192 0S16 78.8 16 176c0 37.3 11.6 71.9 31.4 100.3c5 7.2 10.2 14.3 15.4 21.4c0 0 0 0 0 0c19.8 27.1 39.7 54.4 49.2 86.2l160 0zM192 512c44.2 0 80-35.8 80-80l0-16-160 0 0 16c0 44.2 35.8 80 80 80zM112 176c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-61.9 50.1-112 112-112c8.8 0 16 7.2 16 16s-7.2 16-16 16c-44.2 0-80 35.8-80 80z" />
              </svg>{" "} */}
              CAMP DETAILS & REGISTRATION{" "}
            </h3>
            <h4>EARLY BIRD PRICING UNTIL MARCH 1!</h4>
            <ul className="summerCamp_paras_squareList">
              <li>
                <svg
                  height="18"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  fill="#10b981"
                >
                  <path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                </svg>{" "}
                <b>Best for:</b> K-8th grade students
              </li>

              <li>
                <svg
                  height="18"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  fill="#10b981"
                >
                  <path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                </svg>{" "}
                <b> Camp Hours: </b> 9:00 AM - 3:00 PM
              </li>
              <li>
                <svg
                  height="18"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  fill="#10b981"
                >
                  <path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                </svg>{" "}
                <b> Includes: </b> All materials + take-home projects
              </li>
              <li>
                <svg
                  height="18"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  fill="#10b981"
                >
                  <path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                </svg>{" "}
                <b> Bring: </b> Lunch & two snacks
              </li>
              <li>
                <svg
                  height="18"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  fill="#10b981"
                >
                  <path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                </svg>{" "}
                <b> Sibling Discount: </b> 5% off for each additional child
                (refunded after payment)
              </li>
              <li>
                <svg
                  height="18"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  fill="#10b981"
                >
                  <path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                </svg>{" "}
                <b>Volunteers Welcome! </b> Email{" "}
                <a href="mailto:hello@creatorzone.org">hello@creatorzone.org</a>
              </li>
            </ul>
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                height="16"
              >
                <path d="M512 80c8.8 0 16 7.2 16 16l0 32L48 128l0-32c0-8.8 7.2-16 16-16l448 0zm16 144l0 192c0 8.8-7.2 16-16 16L64 432c-8.8 0-16-7.2-16-16l0-192 480 0zM64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l448 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zm56 304c-13.3 0-24 10.7-24 24s10.7 24 24 24l48 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-48 0zm128 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l112 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-112 0z" />
              </svg>{" "}
              <b> Need payment arrangements? </b> We offer{" "}
              <b> deposit & delayed payment options</b>—email{" "}
              <a href="mailto:hello@creatorzone.org">hello@creatorzone.org</a>{" "}
              for details.
            </p>
            <h4 className="summerCamp_paras_noUnderline">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M480 32c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9L381.7 53c-48 48-113.1 75-181 75l-8.7 0-32 0-96 0c-35.3 0-64 28.7-64 64l0 96c0 35.3 28.7 64 64 64l0 128c0 17.7 14.3 32 32 32l64 0c17.7 0 32-14.3 32-32l0-128 8.7 0c67.9 0 133 27 181 75l43.6 43.6c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6l0-147.6c18.6-8.8 32-32.5 32-60.4s-13.4-51.6-32-60.4L480 32zm-64 76.7L416 240l0 131.3C357.2 317.8 280.5 288 200.7 288l-8.7 0 0-96 8.7 0c79.8 0 156.5-29.8 215.3-83.3z" />
              </svg>
              After Registration:
            </h4>
            <p>
              {" "}
              Our team will reach out with <b>intake forms</b>.
            </p>
            <h4 className="summerCamp_paras_noUnderline">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
              </svg>
              Questions?
            </h4>
            <p>
              Email us at{" "}
              <a href="mailto:hello@creatorzone.org">hello@creatorzone.org</a>{" "}
              or send us your <b> phone number and best contact time </b> for a
              callback.
            </p>
            <p>
              {" "}
              We can't wait to make this summer an <b> unforgettable </b>{" "}
              experience!
            </p>
            <h4 className="summerCamp_paras_noUnderline">- The CZ Team</h4>
            <p></p>
          </div>

          {/* CANCELLATION POLICY */}
          <div>
            <h3> CREATOR ZONE CAMP CANCELLATION POLICY </h3>
            <p>
              <b>Updated April 2025 </b>
            </p>
            <p>
              At Creator Zone, we understand that life happens and plans can
              change. To ensure fairness for all families while supporting our
              instructors and planning process, please review our cancellation
              policy below.
            </p>
            <h4>Family-Initiated Cancellations</h4>
            <ul className="mb-4">
              <li className="mb-2">
                <div>
                  <b> More than 21 days before camp start: </b>
                </div>
                <div className="list-item list-style-none items-center gap-1 ">
                  <svg
                    height="18"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    fill="#10b981"
                    className="mr-1 "
                    style={{
                      transform: "translateY(2.5px)",
                    }}
                  >
                    <path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                  </svg>
                  Full refund, minus a <b> $25 administrative fee </b> per
                  camper, per camp week
                </div>
              </li>

              <li className="mb-2">
                <div>
                  <b> 8-21 days before camp start: </b>
                </div>
                <div className="list-item list-style-none items-center gap-1 ">
                  <svg
                    height="18"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    fill="#10b981"
                    className="mr-1 "
                    style={{
                      transform: "translateY(2.5px)",
                    }}
                  >
                    <path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                  </svg>
                  <b> 50% refund </b> of the total camp fee
                </div>
                <div className="list-item list-style-none items-center gap-1 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    height="15"
                    className="mr-1 "
                    style={{
                      transform: "translateY(2.5px)",
                    }}
                  >
                    <path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160 352 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l111.5 0c0 0 0 0 0 0l.4 0c17.7 0 32-14.3 32-32l0-112c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1L16 432c0 17.7 14.3 32 32 32s32-14.3 32-32l0-35.1 17.6 17.5c0 0 0 0 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.8c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352l34.4 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L48.4 288c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z" />
                  </svg>
                  Or, transfer your full payment to another available camp week
                </div>
              </li>

              <li>
                <div>
                  <b> 7 days or less before camp start:</b>
                </div>
                <div className="list-item list-style-none items-center gap-1 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    height="18"
                    className="mr-1 "
                    style={{
                      transform: "translateY(2.5px)",
                    }}
                    fill="#ba000d"
                  >
                    <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                  </svg>
                  No refunds available
                </div>
                <div className="list-item list-style-none items-center gap-1 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    height="15"
                    className="mr-1 "
                    style={{
                      transform: "translateY(2.5px)",
                    }}
                  >
                    <path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160 352 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l111.5 0c0 0 0 0 0 0l.4 0c17.7 0 32-14.3 32-32l0-112c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1L16 432c0 17.7 14.3 32 32 32s32-14.3 32-32l0-35.1 17.6 17.5c0 0 0 0 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.8c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352l34.4 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L48.4 288c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z" />
                  </svg>
                  Transfers <b> may </b> be possible upon request, based on
                  availability{" "}
                </div>
              </li>
            </ul>

            <h4>Missed Days or No-Shows </h4>
            <p className="mb-4">
              We do not offer refunds or credits for missed camp days or
              no-shows.
            </p>

            <h4>Camps Canceled by Creator Zone </h4>
            <p className="">
              In the rare case that Creator Zone cancels a camp (due to low
              enrollment, instructor illness, or emergencies):
            </p>
            <div className="list-item list-style-none items-center gap-1 mb-4">
              <svg
                height="18"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                fill="#10b981"
                className="mr-1 "
                style={{
                  transform: "translateY(2.5px)",
                }}
              >
                <path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
              </svg>
              You'll receive a full refund or the option to transfer your
              payment to another program
            </div>

            <h4>Installment Plan Refunds</h4>
            <ul className="mb-4">
              <li>
                Refunds are based on the <b> total cost </b> of the camp, not
                the amount paid to date.
              </li>
              <li>
                Any unpaid balance at the time of cancellation may still be due
                based on this policy.
              </li>
            </ul>

            <h4>To Cancel a Registration</h4>
            <p>
              Email your request{" "}
              <a href="mailto:hello@creatorzone.org">hello@creatorzone.org</a>{" "}
            </p>
            <p> Include your camper's name and the week you're canceling.</p>
            <p>
              <b> Note: </b> All refund requests must be submitted in writing.
              Refunds will be processed within 7–10 business days.
            </p>
            <p>
              <b>
                Thank you for understanding and supporting our mission to spark
                creativity in every child!
              </b>
            </p>
          </div>

          {/* Creator Zone Installment Plan Terms & Conditions */}
          <div>
            <h3>Creator Zone Installment Plan Terms & Conditions</h3>
            <p>
              <b> Interest-Free Weekly Payment Plan </b>
            </p>
            <p>
              By enrolling in a Creator Zone Camp and selecting an installment
              plan, you agree to the following terms:
            </p>

            <h4 className="summerCamp_paras_noUnderline">
              1. Plan Eligibility
            </h4>
            <ul className="mb-4">
              <li>
                Installment plans are available only for families who register{" "}
                <b> 4 or more weeks prior to the start of camp</b>.
              </li>
              <li>
                The length of your plan (4, 6, 9, or 12 weeks) is determined by
                how early you register.
              </li>
              <li>
                The <b> first payment </b> is due at the time of registration.
              </li>
            </ul>

            <h4 className="summerCamp_paras_noUnderline">
              2. Automatic Payments
            </h4>
            <ul className="mb-4">
              <li>
                Remaining payments will be automatically charged on a{" "}
                <b> weekly basis </b>
                using the payment method provided.
              </li>
              <li>
                You must keep a <b> valid credit or debit card </b> on file for
                the full duration of the plan.
              </li>
              <li>Payment reminders will be sent via email.</li>
            </ul>

            <h4 className="summerCamp_paras_noUnderline">
              3. Full Payment Requirement
            </h4>
            <ul className="mb-4">
              <li>
                The <b> total camp fee must be paid in full </b> no later than
                the <b> Friday before your child's camp week begins</b>.
              </li>
              <li>
                If the full balance is not paid by that date, your child may
                lose their spot and no refund will be issued for prior payments.
              </li>
            </ul>

            <h4 className="summerCamp_paras_noUnderline">
              4. Late or Failed Payments
            </h4>
            <ul className="mb-4">
              <li>
                If a scheduled payment fails, you'll be notified and have{" "}
                <b> 3 business days </b> to update your payment method or
                manually pay.
              </li>
              <li>
                A failed or unresolved payment may result in a{" "}
                <b> hold or cancellation </b> of your child's registration.
              </li>
            </ul>

            <h4 className="summerCamp_paras_noUnderline">
              5. Cancellations and Refunds
            </h4>
            <ul className="mb-4">
              <li>
                Refunds for installment plans follow our standard{" "}
                <b> Camp Cancellation Policy</b>.
              </li>
              <li>
                If you cancel your registration, refunds (if applicable) will be
                calculated based on the <b> total camp cost</b>, not the amount
                paid to date.
              </li>
              <li>
                You may still owe a balance depending on the cancellation date.
              </li>
            </ul>

            <h4 className="summerCamp_paras_noUnderline">
              6. Modifications & Responsibility
            </h4>
            <ul className="mb-4">
              <li>
                It is your responsibility to monitor your payments and ensure
                your account is in good standing.
              </li>
              <li>
                Creator Zone reserves the right to modify or discontinue
                installment plan offerings at any time (this will not affect
                current plans already in progress).
              </li>
            </ul>

            <h4 className="summerCamp_paras_noUnderline">
              7. Agreement Confirmation
            </h4>
            <ul className="mb-4">
              <li>
                By selecting a payment plan and submitting your first payment,
                you acknowledge that you have read and agreed to these terms and
                conditions.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummerCamp;
